// Color
$primary-color          : #2a98fe;

$info-color             : #2db7f5;
$success-color          : #19be6b;
$warning-color          : #ff9900;
$error-color            : #ed4014;
$normal-color           : #e6ebf1;
$link-color             : #2D8cF0;
$link-hover-color       : lighten($link-color, 20%);
$link-active-color      : darken($link-color, 5%);
$selected-color         : rgba($primary-color, 0.8);
$tooltip-color          : #fff;
$subsidiary-color       : #808695;
$rate-star-color        : #f5a623;


// Base
$body-background        : #fff;
$font-family            : "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
$code-family            : Consolas,Menlo,Courier,monospace;
$title-color            : #17233d;
$text-color             : #515a6e;
$font-size-base         : 14px;
$font-size-small        : 12px;
$font-size-large        : 16px;
$line-height-base       : 1.5;
$line-height-computed   : floor(($font-size-base * $line-height-base));
$border-radius-base     : 6px;
$border-radius-small    : 4px;
$cursor-disabled        : not-allowed;


// Border
$border-color-base      : #e8e8e8;  // outside
$border-color-split     : #e8eaec;  // inside
$border-width-base      : 1px;            // width of the border for a component
$border-style-base      : solid;          // style of a components border
$border-base            : $border-width-base $border-style-base $border-color-base;

// Background color
$background-color-base  : #ffffff;  // base


// Shadow
$shadow-color           : rgba(0, 0, 0, .2);
$shadow-base            : 0 0px 6px $shadow-color;
$shadow-card            : 0 1px 1px 0 rgba(0,0,0,.1);
$shadow-up              : 0 -1px 6px $shadow-color;
$shadow-down            : 0 1px 6px $shadow-color;
$shadow-left            : -1px 0 6px $shadow-color;
$shadow-right           : 1px 0 6px $shadow-color;


// Z-index
$zindex-spin            : 8;
$zindex-affix           : 10;
$zindex-back-top        : 10;
$zindex-select          : 900;
$zindex-modal           : 1000;
$zindex-drawer          : 1000;
$zindex-message         : 1010;
$zindex-notification    : 1010;
$zindex-tooltip         : 1060;
$zindex-transfer        : 1060;
$zindex-loading-bar     : 2000;
$zindex-spin-fullscreen : 2010;

// Animation
$animation-time         : .3s;
$transition-time        : .2s;
$ease-in-out : ease-in-out;
.login-pg {
  height: 100%;
  width: 100%;
}
.login-head {
  height: 44px;
  padding: 0 30px;
  line-height: 44px;
  h2 {
    margin: 0px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #1e2c4a;
  }
}
.login-pg-wrap {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-bg-wrap {
  width: 500px;
  height: 560px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 16px 0px rgba(55,115,255,0.20); 
  margin-bottom: 30px;
  display: flex;
}
.login-bg-lf {
  width: 500px;
  height: 560px;
  opacity: 1;
  border-radius: 8px;
  box-shadow: 2px 0px 16px 0px rgba(55,115,255,0.20); 
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 430px;
    height: auto;
  }
}
.login-bg-rt {
  width: 500px;
  height: 560px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.login-title-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 70px;
  .row-line {
    width: 34px;
    height: 1px;
    background: $primary-color;
    margin-left: 11px;
    margin-right: 11px;
  }
  .login-title {
    font-size: 20px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    text-align: left;
    color: $primary-color;
    line-height: 28px;
  }
}
